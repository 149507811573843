type PaginationParam = {
    first: number
    skip?: number
  }

  interface IGqlUtils{
    getPaginationParams(page: number, itemsPerPage: number) : PaginationParam
  }

export default class GqlUtils implements IGqlUtils {
  getPaginationParams (page: number, itemsPerPage: number) {
    if (page === 1) {
      return {
        first: itemsPerPage,
        skip: 0
      }
    } else {
      return {
        first: itemsPerPage,
        skip: (page - 1) * itemsPerPage
      }
    }
  }
}
